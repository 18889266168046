import React from 'react';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import './index.css';
import { fetchLeaderboardList2 } from '../../actions/home';
import CopyButton from '../../components/CopyButton';
import { config, STREAM_2, STREAMS } from '../../config';
import { floatCount } from '../../utils/price';
import AddressTextField from './AddressTextField';

const ActivePoolsTable2 = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress ? <CircularProgress/> : 'No Data',
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        // onRowClick: (rowData, rowState) => {
        //     handleClick(rowData && rowData[1]);
        // },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchLeaderboardList(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            const address = (props.addressValue !== '' && props.valid) ? props.addressValue : '';

            props.fetchLeaderboardList(props.limit * currentPage, props.limit, props.sortBy, props.order, address);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            const address = (props.addressValue !== '' && props.valid) ? props.addressValue : '';
            props.fetchLeaderboardList(props.skip, numberOfRows, props.sortBy, props.order, address);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            const address = (props.addressValue !== '' && props.valid) ? props.addressValue : '';
            props.fetchLeaderboardList(props.skip, props.limit, changedColumn, direction, address);
        },
        responsive: 'standard',
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: false,
        serverSide: true,
        pagination: true,
        count: props.count,
    };

    const columns = [{
        name: 'address',
        label: 'Address',
        options: {
            sort: true,
            customBodyRender: function (item) {
                return (
                    <div className="address_value clm_value">
                        {item}
                        <CopyButton data={item} />
                    </div>
                );
            },
        },
    // }, {
    //     name: 'stream_' + (STREAMS && STREAMS[0]),
    //     label: STREAM_8 + '/' + config.DENOM,
    //     options: {
    //         sort: true,
    //         customBodyRender: function (item) {
    //             return (
    //                 <div className="clm_value">
    //                     {item
    //                         ? floatCount(item / (10 ** config.COIN_DECIMALS), 6)
    //                         : '0'}
    //                 </div>
    //             );
    //         },
    //     },
    // }, {
    //     name: 'stream_' + (STREAMS && STREAMS[1]),
    //     label: STREAM_9 + '/' + config.DENOM,
    //     options: {
    //         sort: true,
    //         customBodyRender: function (item) {
    //             return (
    //                 <div className="clm_value">
    //                     {item
    //                         ? floatCount(item / (10 ** config.COIN_DECIMALS), 6)
    //                         : '0'}
    //                 </div>
    //             );
    //         },
    //     },
    // }, {
    //     name: 'stream_' + (STREAMS && STREAMS[2]),
    //     label: STREAM_10 + '/' + config.DENOM,
    //     options: {
    //         sort: true,
    //         customBodyRender: function (item) {
    //             return (
    //                 <div className="clm_value">
    //                     {item
    //                         ? floatCount(item / (10 ** config.COIN_DECIMALS), 6)
    //                         : '0'}
    //                 </div>
    //             );
    //         },
    //     },
    }, {
        name: 'stream_' + (STREAMS && STREAMS[0]),
        label: STREAM_2 + '/OM',
        options: {
            sort: true,
            customBodyRender: function (item) {
                return (
                    <div className="clm_value">
                        {item
                            ? floatCount(item / (10 ** config.COIN_DECIMALS), 6)
                            : '0'}
                    </div>
                );
            },
        },
    }, {
    //     name: 'stream_' + (STREAMS && STREAMS[2]),
    //     label: 'STREAM-' + (STREAMS && STREAMS[2]) + ' (' + config.DENOM + ')',
    //     options: {
    //         sort: true,
    //         customBodyRender: function (item) {
    //             return (
    //                 <div className="clm_value">
    //                     {item
    //                         ? floatCount(item / (10 ** config.COIN_DECIMALS))
    //                         : '0'}
    //                 </div>
    //             );
    //         },
    //     },
    // }, {
        name: 'total',
        label: 'Total',
        options: {
            sort: true,
            customBodyRender: function (item) {
                return (
                    <div className="clm_value">
                        {item
                            ? floatCount(item / (10 ** config.COIN_DECIMALS), 6)
                            : '0'}
                    </div>
                );
            },
        },
    }];

    const variables = [];
    STREAMS.map((val) => variables.push[`stream_${val}`]);

    const tableData =
        props.list && props.list.length > 0
            ? props.list.map((item, index) => {
                const array = [
                    item.address,
                ];
                STREAMS.map((val) => {
                    array.push(item[`stream_${val}`]);
                });
                array.push(item.total);

                return array;
            })
            : [];

    return (
        <div className="data_table">
            <div className="filter_section">
                <AddressTextField />
            </div>
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </div>
    );
};

ActivePoolsTable2.propTypes = {
    addressValue: PropTypes.string.isRequired,
    fetchLeaderboardList: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    sortBy: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    count: PropTypes.number,
    limit: PropTypes.number,
    order: PropTypes.string,
    skip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.leaderboardList2.list,
        inProgress: state.home.leaderboardList2.inProgress,
        skip: state.home.leaderboardList2.skip,
        limit: state.home.leaderboardList2.limit,
        count: state.home.leaderboardList2.count,
        sortBy: state.home.leaderboardList2.sortBy,
        order: state.home.leaderboardList2.order,
        search: state.home.leaderboardList2.search,

        addressValue: state.home.accountAddress.value,
        valid: state.home.accountAddress.valid,
    };
};

const actionToProps = {
    fetchLeaderboardList: fetchLeaderboardList2,
};

export default connect(stateToProps, actionToProps)(ActivePoolsTable2);
