import { urlStreamsList, urlStreamsList2 } from '../constants/url';
import {
    ADDRESS_SET, FILTER_SET,
    LEADERBOARD_LIST2_ERROR,
    LEADERBOARD_LIST2_IN_PROGRESS,
    LEADERBOARD_LIST2_SUCCESS,
    LEADERBOARD_LIST_ERROR,
    LEADERBOARD_LIST_IN_PROGRESS,
    LEADERBOARD_LIST_SUCCESS,
    TABS_SET,
} from '../constants/home';
import Axios from 'axios';

export const setFilter = (value) => {
    return {
        type: FILTER_SET,
        value,
    };
};

export const setAddress = (value, valid) => {
    return {
        type: ADDRESS_SET,
        value,
        valid,
    };
};

const fetchLeaderboardListInProgress = () => {
    return {
        type: LEADERBOARD_LIST_IN_PROGRESS,
    };
};

const fetchLeaderboardListSuccess = (value, count, skip, limit, sort, order) => {
    return {
        type: LEADERBOARD_LIST_SUCCESS,
        value,
        count,
        skip,
        limit,
        sort,
        order,
    };
};

const fetchLeaderboardListError = (message) => {
    return {
        type: LEADERBOARD_LIST_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchLeaderboardList = (skip, limit, sort, order, address, cb) => (dispatch) => {
    dispatch(fetchLeaderboardListInProgress());

    const url = urlStreamsList(skip, limit, sort, order, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchLeaderboardListSuccess(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit, sort, order));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchLeaderboardListError(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const setTabs = (value) => {
    return {
        type: TABS_SET,
        value,
    };
};

const fetchLeaderboardList2InProgress = () => {
    return {
        type: LEADERBOARD_LIST2_IN_PROGRESS,
    };
};

const fetchLeaderboardList2Success = (value, count, skip, limit, sort, order) => {
    return {
        type: LEADERBOARD_LIST2_SUCCESS,
        value,
        count,
        skip,
        limit,
        sort,
        order,
    };
};

const fetchLeaderboardList2Error = (message) => {
    return {
        type: LEADERBOARD_LIST2_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchLeaderboardList2 = (skip, limit, sort, order, address, cb) => (dispatch) => {
    dispatch(fetchLeaderboardList2InProgress());

    const url = urlStreamsList2(skip, limit, sort, order, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchLeaderboardList2Success(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit, sort, order));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list);
            }
        })
        .catch((error) => {
            dispatch(fetchLeaderboardList2Error(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
