import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { setTabs } from '../../actions/home';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const StreamTabs = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabs(newValue);
    };

    return (
        <Tabs aria-label="stream tabs" className="tabs" value={props.tabValue} onChange={handleChange}>
            <Tab label="USDC" value="usdc"/>
            <Tab label="OM" value="om"/>
        </Tabs>
    );
};

StreamTabs.propTypes = {
    setTabs: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        tabValue: state.home.tabValue,
    };
};

const actionToProps = {
    setTabs,
};

export default connect(stateToProps, actionToProps)(StreamTabs);
