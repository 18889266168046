import { config, STREAMS, DEV_URL } from '../config';

export const urlStreamsList = (skip, limit, sortBy, order, address) => {
    const params = [];

    if (STREAMS && STREAMS.length > 0) {
        STREAMS.map((item) => {
            params.push(`streamIds[]=${item}`);
        });
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (address) {
        params.push(`address=${address}`);
    }

    return `${DEV_URL}/contracts/${config.CONTRACT_ADDRESS}/leaderboard?${params.join('&')}`;
};

export const urlStreamsList2 = (skip, limit, sortBy, order, address) => {
    const params = [];

    if (STREAMS && STREAMS.length > 0) {
        STREAMS.map((item) => {
            params.push(`streamIds[]=${item}`);
        });
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (address) {
        params.push(`address=${address}`);
    }

    return `${DEV_URL}/contracts/${config.CONTRACT_ADDRESS2}/leaderboard?${params.join('&')}`;
};
