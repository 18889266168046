import { combineReducers } from 'redux';
import {
    ADDRESS_SET, FILTER_SET,
    LEADERBOARD_LIST2_ERROR,
    LEADERBOARD_LIST2_IN_PROGRESS,
    LEADERBOARD_LIST2_SUCCESS,
    LEADERBOARD_LIST_ERROR,
    LEADERBOARD_LIST_IN_PROGRESS,
    LEADERBOARD_LIST_SUCCESS,
    TABS_SET,
} from '../constants/home';
import { DEFAULT_ORDER, DEFAULT_SORT_BY } from '../config';

const leaderboardList = (state = {
    inProgress: false,
    list: [],
    skip: 0,
    limit: 10,
    count: null,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case LEADERBOARD_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LEADERBOARD_LIST_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
            skip: action.skip,
            limit: action.limit,
            count: action.count,
            sortBy: action.sort,
            order: action.order,
        };
    case LEADERBOARD_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const leaderboardList2 = (state = {
    inProgress: false,
    list: [],
    skip: 0,
    limit: 10,
    count: null,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case LEADERBOARD_LIST2_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LEADERBOARD_LIST2_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
            skip: action.skip,
            limit: action.limit,
            count: action.count,
            sortBy: action.sort,
            order: action.order,
        };
    case LEADERBOARD_LIST2_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const accountAddress = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ADDRESS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    default:
        return state;
    }
};

const filter = (state = {
    open: true,
}, action) => {
    switch (action.type) {
    case FILTER_SET:
        return {
            open: action.value,
        };

    default:
        return state;
    }
};

const tabValue = (state = 'usdc', action) => {
    switch (action.type) {
    case TABS_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    leaderboardList,
    leaderboardList2,
    accountAddress,
    filter,
    tabValue,
});
