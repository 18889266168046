export const config = {
    COIN_DECIMALS: 6,
    PREFIX: 'mantra',
    // CONTRACT_ADDRESS: 'osmo17kqws7fy2qnejdw68cqjlw456gdxcdukk0wq6026u826zlda3y7qdhzqlv',
    CONTRACT_ADDRESS: 'mantra1sw2xe5hz5fpcnk0eavqde42wen0fpfgfcn5dhh2tncdqauhwynhq8c62cd',
    CONTRACT_ADDRESS2: 'mantra1qyxtrujvsg5adzusp896tak2eutcfn4ucrv984klukywh70jngeq8tkq0h',
    DENOM: 'OSMO',
    DENOM_IBC: 'ibc/498A0751C798A0D9A389AA3691123DADA57DAA4FE165D5C75894505B876BA6E4',
};

export const DEV_URL = 'https://data-api-mantra.streamswap.io';
export const STREAMS = [1];
export const STREAM_8 = 'LAB';
export const STREAM_9 = 'TNKR';
export const STREAM_10 = 'HAVA';
export const STREAM_2 = 'BIKE';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = 'total';
export const DEFAULT_ORDER = 'desc';
