import React from 'react';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLeaderboardList, fetchLeaderboardList2, setFilter } from '../../actions/home';
import Table from './Table';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../config';
import StreamTabs from './Tabs';
import ActivePoolsTable2 from './Table2';

class Home extends React.Component {
    componentDidMount () {
        this.props.fetchLeaderboardList(DEFAULT_SKIP, DEFAULT_LIMIT, DEFAULT_SORT_BY, DEFAULT_ORDER, '');
        this.props.fetchLeaderboardList2(DEFAULT_SKIP, DEFAULT_LIMIT, DEFAULT_SORT_BY, DEFAULT_ORDER, '');
    }

    render () {
        return (
            <div className="home">
                <div className="header">
                    <h2>{variables[this.props.lang]['stream_swap_test_net']}</h2>
                    {/* <Button onClick={() => this.props.setFilter(!this.props.filter)}> */}
                    {/*    Filter */}
                    {/* </Button> */}
                </div>
                {/* {this.props.filter */}
                {/*    ? <div className="filter_section"> */}
                {/*        <AddressTextField /> */}
                {/*    </div> */}
                {/*    : null} */}
                <StreamTabs/>
                <div className="table_section">
                    {this.props.tabValue === 'usdc'
                        ? <Table />
                        : this.props.tabValue === 'om'
                            ? <ActivePoolsTable2/> : null}
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    fetchLeaderboardList: PropTypes.func.isRequired,
    fetchLeaderboardList2: PropTypes.func.isRequired,
    filter: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    setFilter: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
    count: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,

        list: state.home.leaderboardList.list,
        inProgress: state.home.leaderboardList.inProgress,
        limit: state.home.leaderboardList.limit,
        skip: state.home.leaderboardList.skip,
        count: state.home.leaderboardList.count,

        filter: state.home.filter.open,
        tabValue: state.home.tabValue,
    };
};

const actionToProps = {
    fetchLeaderboardList,
    fetchLeaderboardList2,
    setFilter,
};

export default connect(stateToProps, actionToProps)(Home);
