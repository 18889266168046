export const LEADERBOARD_LIST_SUCCESS = 'LEADERBOARD_LIST_SUCCESS';
export const LEADERBOARD_LIST_IN_PROGRESS = 'LEADERBOARD_LIST_IN_PROGRESS';
export const LEADERBOARD_LIST_ERROR = 'LEADERBOARD_LIST_ERROR';

export const LEADERBOARD_LIST2_SUCCESS = 'LEADERBOARD_LIST2_SUCCESS';
export const LEADERBOARD_LIST2_IN_PROGRESS = 'LEADERBOARD_LIST2_IN_PROGRESS';
export const LEADERBOARD_LIST2_ERROR = 'LEADERBOARD_LIST2_ERROR';

export const FILTER_SET = 'FILTER_SET';
export const ADDRESS_SET = 'ADDRESS_SET';

export const TABS_SET = 'TABS_SET';
